/* eslint-disable no-irregular-whitespace */
import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Box, Card, Heading, Text } from 'rebass/styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Hero } from '../../components/hero'
import { HeroLink } from '../../components/hero-link'
import { ContentSection } from '../../sections/content'
import reliefBg from '../../images/relief-bg.png'

const CompanyCard = ({ title, text, ctaLabel, ctaLink, children }) => {
  return (
    <Card sx={{ gap: 3 }}>
      <Box sx={{ display: 'grid', gap: 3 }}>
        {ctaLink && (
          <Link to={ctaLink}>
            {children}
          </Link>
        )}

        {!ctaLink && children}

        <Box sx={{ display: 'grid', gap: 2 }}>
          <Heading variant="cardTitle">{title}</Heading>

          {text && <Text variant="cardText">{text}</Text>}
        </Box>
      </Box>

      {ctaLabel && ctaLink && (
        <HeroLink dark style={{ alignSelf: 'end' }} to={ctaLink}>
          {ctaLabel}
        </HeroLink>
      )}
    </Card>
  )
}

const OfferPage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/nabizime-hero-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO title="Naše nabídka produktů a služeb" />

      <Hero
        image={heroBg}
        bgPosition="center 60%"
        title="Naše nabídka<br />produktů a služeb"
      />

      <ContentSection
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url(${reliefBg})`,
          backgroundSize: '100%, 50%',
        }}
      >
        <Box sx={{ position: 'relative', mt: -6 }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
              gap: 3,
              mb: 3,
            }}
          >
            <CompanyCard
              title="Verinlegno"
              text="rodinná líheň inovací z Itálie"
              ctaLabel="Více o Verinlegno"
              ctaLink="/nabizime/verinlegno"
            >
              <StaticImage
                src="../../images/verinlegno-logo.png"
                alt=""
                placeholder="none"
                quality={90}
                height={89}
                objectFit="contain"
                objectPosition="0"
              />
            </CompanyCard>

            <CompanyCard
              title="Arboritec"
              text="kvalita, servis a udržitelnost ve skandinávském stylu"
              ctaLabel="Více o Arboritec"
              ctaLink="/nabizime/arboritec"
            >
              <StaticImage
                src="../../images/arboritec-logo.png"
                alt=""
                placeholder="none"
                quality={90}
                height={89}
                objectFit="contain"
                objectPosition="0"
              />
            </CompanyCard>

            <CompanyCard
              title="Vzorování odstínu na míru"
              text="když se sny zákazníků začnou plnit"
              ctaLabel="Více o vzorování"
              ctaLink="/nabizime/vzorovani"
            >
              <StaticImage
                src="../../images/frypo-logo.png"
                alt=""
                placeholder="none"
                quality={90}
                height={89}
                objectFit="contain"
                objectPosition="0"
              />
            </CompanyCard>
          </Box>

          <Card
            sx={{
              gridTemplateColumns: ['1fr', '1fr auto'],
              gridTemplateRows: '1fr',
              gap: 3,
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              bg: 'rgba(255, 255, 255, 0.8)',
              boxShadow: 'none',
            }}
          >
            <Box sx={{ display: 'grid', gap: 2 }}>
              <Heading variant="cardTitle">Nanotechnologie</Heading>

              <Text variant="cardText">
                proč používat oleje a laky s nanočásticemi
              </Text>
            </Box>

            <HeroLink dark to="/nabizime/nanotechnologie">
              Více o nanotechnologiích
            </HeroLink>
          </Card>
        </Box>
      </ContentSection>
    </Layout>
  )
}

export default OfferPage

CompanyCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  children: PropTypes.node.isRequired,
}
